import React from 'react';
import BanhoTosaBenefits from '../../components/benefits/BanhoTosaBenefits';
import BanhoTosaGallery from '../../components/gallery/BanhoTosaGallery';
import './css/BanhoTosa.css';
import Headline from '../../components/headline/Headline';

const BanhoTosa = (props) => {
    const { isMobile, isTablet, isLaptop, banhoTosaHeroDog, banhoTosaCat } = props;
    return (
        <div className='banho-tosa-container'>
            <div className='banho-tosa-large-bg'>
                <div className='banho-tosa-small-bg'>
                    <Headline isMobile={isMobile} isTablet={isTablet} isLaptop={isLaptop} data={banhoTosaHeroDog} />
                    <BanhoTosaBenefits />
                </div>
            </div>
            <div className='banho-tosa-large-bg'>
                <div className='banho-tosa-small-bg'>
                    <BanhoTosaGallery isMobile={isMobile} isTablet={isTablet} />
                    <Headline isMobile={isMobile} data={banhoTosaCat} />
                </div>
            </div>
        </div>
    )
}

export default BanhoTosa;
