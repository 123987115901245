import React, { useState, useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { HashLink } from 'react-router-hash-link';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import CallIcon from '@mui/icons-material/Call';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import successImage from '../../assets/dogs-contact-form-success.png';
import ReCAPTCHA from "react-google-recaptcha";
import './css/ContactForm.css';

const Result = () => {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#00477A',
            }
        }, typography: {
            button: {
                textTransform: 'none'
            }
        }
    });

    return (
        <div className='contact-form-success-msg'>
            <p>Sua mensagem foi enviada.
                <br />
                Entraremos em contato em breve. Obrigado!
            </p>
            <ThemeProvider theme={theme}>
                <Button
                    LinkComponent={HashLink}
                    to='/'
                    type='submit'
                    variant="text"
                    disableElevation
                    style={{ color: "#00477A" }}
                >
                    Retornar
                </Button>
            </ThemeProvider>
            <img src={successImage} alt="" />
        </div>
    )
}

const ContactForm = (props) => {
    const { isMobile } = props;
    const theme = createTheme({
        palette: {
            primary: {
                main: '#00477A',
            }
        }, typography: {
            button: {
                textTransform: 'none'
            }
        }
    });

    const [result, showResult] = useState(false);
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_spuvkmz', 'template_t3r13so', form.current, process.env.REACT_APP_EMAILJS_KEY)
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
        showResult(true);
    };

    const [isVerified, setIsVerified] = useState(true);
    const handleOnChange = () => {
        setIsVerified(false);
    }

    return (
        <div className='contact-form-container'>
            {result ? (
                <Result />
            ) : (
                <form id='form' ref={form} action='' onSubmit={sendEmail}>
                    <div className='contact-form-content'>
                        <div className='contact-form-opening-hours'>
                            <div className='contact-form-opening-hours-item'>
                                <div><QueryBuilderIcon /></div>
                                <div>
                                    <h4>Seg. a Sáb. 09:00 às 18:00</h4>
                                </div>
                            </div>
                            <div className='contact-form-opening-hours-item'>
                                <div><CallIcon /></div>
                                <div>
                                    <h4>11-99321-4159</h4>
                                </div>
                            </div>

                            <div className='contact-form-opening-hours-item'>
                                <div><AlternateEmailIcon /></div>
                                <div>
                                    <h4>petshopblue619@gmail.com</h4>
                                </div>
                            </div>
                        </div>
                        {isMobile ? (
                            <a href='#form'>
                                <h4>Formulário de Contato</h4>
                            </a>
                        ) : (
                            null
                        )}
                        <div className='contact-form-input-box'>
                            <div className="contact-form-input-item">
                                <label className="contact-form-input-label">
                                    <input type="text" name="fullName" required />
                                    <span className="contact-form-input-span">Nome</span>
                                </label>
                            </div>
                            <div className="contact-form-input-item">
                                <label className="contact-form-input-label">
                                    <input type="text" name="tel" required />
                                    <span className="contact-form-input-span">Telefone</span>
                                </label>
                            </div>
                            <div className="contact-form-input-item">
                                <label className="contact-form-input-label">
                                    <input type="text" name="email" required />
                                    <span className="contact-form-input-span">Email</span>
                                </label>
                            </div>
                        </div>
                        <div className='contact-form-textarea-box'>
                            <div className="contact-form-textarea-item">
                                <label className="contact-form-textarea-label">
                                    <textarea name='message' className="contact-form-textarea" required></textarea>
                                    <span className="contact-form-textarea-span">Messagem</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='contact-form-submit'>
                        <div className='contact-form-recaptcha'>
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_RECAPTCHA_SECRET_KEY}
                                onChange={handleOnChange}
                            />
                        </div>
                        <div className='contact-form-btn'>
                            <ThemeProvider theme={theme}>
                                <Button
                                    disabled={isVerified}
                                    type='submit'
                                    variant="contained"
                                    disableElevation
                                    style={{ color: "#fff" }}
                                >
                                    Enviar Mensagem
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                </form>
            )
            }

        </div>
    );
}

export default ContactForm;