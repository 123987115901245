import React, { useMemo } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { NavLink } from "react-router-dom";
import logo from "../../assets/footer-logo.png";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import './css/Footer.css';
const year = new Date().getFullYear();

function Map() {
    const center = useMemo(() => ({ lat: -23.17940779588486, lng: - 46.92136787847464 }), []);

    return (
        <GoogleMap
            zoom={15}
            center={center}
            mapContainerClassName="map-container"
        >
            <Marker
                title={'Pet Shop Blue'}
                name={'Pet Shop Blue'}
                position={center}
            />
        </GoogleMap>
    )
}

const Footer = (props) => {
    const { isMobile } = props;
    const { isLoaded } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY })
    return (
        <footer>
            <div className="footer-content">
                <div className='footer-box'>
                    <div className="footer-column footer-logo">
                        <img src={logo} alt="" />
                        <h2>Pet Shop Blue</h2>
                    </div>
                    <div className="footer-column footer-sitemap">
                        <ul>
                            <h4>Sitemap</h4>
                            <li>
                                <NavLink
                                    to="/"
                                >
                                    Home
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/banho-tosa"
                                >
                                    Banho e Tosa
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/atendimento"
                                >
                                    Atendimento
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/termos-servicos"
                                >
                                    Termos e Serviços
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-column footer-contact">
                        <ul>
                            <h4>Contato</h4>
                            {isMobile ? (
                                <li>
                                    <LocationOnIcon />
                                    <p>Av. Amélia Latorre, 547</p>
                                    <br />
                                    <p>Retiro, Jundiaí - SP 13211-815</p>
                                </li>
                            ) : (
                                <li>
                                    <LocationOnIcon />
                                    <p>Av. Amélia Latorre, 547 - Retiro <br /> Jundiaí - SP 13211-815</p>
                                </li>
                            )}
                            <li>
                                <CallIcon />
                                <p>11-99321-4159</p>
                            </li>
                            <li>
                                <AlternateEmailIcon />
                                <p>petshopblue619@gmail.com</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='footer-map'>
                    {isLoaded ? <Map /> : <h4>Loading...</h4>}
                </div>
                <div className="footer-copyright">
                    <p>
                        Todos os Direitos Reseverados <span>&#169;</span> {year} Pet Shop Blue
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;