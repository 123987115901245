import React, { useState, useEffect } from "react";
import NavbarSmall from './components/navbar/NavbarSmall';
import Navbar from './components/navbar/Navbar';
import { Routes, Route } from 'react-router-dom';
import Media from 'react-media';
import { Fragment } from 'react';
import Home from './pages/home/Home';
import BanhoTosa from './pages/banho-tosa/BanhoTosa';
import Atendimento from './pages/atendimento/Atendimento';
import Footer from './components/footer/Footer';
import {
  homeHero,
  homeBanhoTosaHeadline,
  banhoTosaHeroHeadline,
  banhoTosaCatHeadline,
  petServicesData
} from './Data';
import './App.css';

const App = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [isTablet, setIsTablet] = useState(window.innerWidth < 1008);
  const [isLaptop, setIsLaptop] = useState(window.innerWidth > 1200 && window.innerHeight < 675);
  const updateMedia = () => {
    if (isMobile < 640) {
      setIsMobile(window.innerWidth < 640);
    }
    if (isTablet < 1008) {
      setIsTablet(window.innerWidth < 1008);
    }
    if (isLaptop === (window.innerWidth > 1200 && window.innerHeight < 675)) {
      setIsLaptop(window.innerWidth > 1200 && window.innerHeight < 675);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const [sidebar, setSidebar] = useState(false);

  return (
    <div className={`App ${sidebar ? "hide-overflow" : "show-overflow"}`}>
      <div className="App-container">
        <Media queries={{
          small: "(max-width: 640px)",
          medium: "(min-width: 641px)"
        }}>
          {matches => (
            <Fragment>
              {matches.small &&
                <NavbarSmall sidebar={sidebar} setSidebar={setSidebar} />
              }
              {matches.medium &&
                <Navbar />
              }
            </Fragment>
          )}
        </Media>
        <div className='app-content'>
          <Routes>
            <Route path="/" element={<Home isMobile={isMobile} isTablet={isTablet} isLaptop={isLaptop} homeBanhoTosaHero={homeBanhoTosaHeadline} homeHero={homeHero} petServicesData={petServicesData} />} />
            <Route path="/banho-tosa" element={<BanhoTosa isMobile={isMobile} isTablet={isTablet} isLaptop={isLaptop} banhoTosaHeroDog={banhoTosaHeroHeadline} banhoTosaCat={banhoTosaCatHeadline} />} />
            <Route path="/atendimento" element={<Atendimento isMobile={isMobile} isTablet={isTablet} />} />
          </Routes>
        </div>
      </div>
      <Footer isMobile={isMobile} />
    </div>
  );
}

export default App;
