import React, { useEffect } from 'react';
import HomeHero from '../../components/hero/HomeHero';
import HomeBenefits from '../../components/benefits/HomeBenefits';
import HomeGallery from '../../components/gallery/HomeGallery';
import Brands from '../../components/brands/Brands';
import Headline from '../../components/headline/Headline';
import PetServices from '../../components/pet-services/PetServices';
import linkPreview from '../../assets/link-preview.png';
import AOS from "aos";
import "aos/dist/aos.css";
import './css/Home.css';

const Home = (props) => {
    const { isMobile, isTablet, isLaptop, homeBanhoTosaHero, homeHero, petServicesData } = props;
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <div className='home-container'>
            <div className='home-large-bg'>
                <div className='home-small-bg'>
                    <HomeHero isMobile={isMobile} isTablet={isTablet} data={homeHero} />
                    <HomeBenefits isMobile={isMobile} isTablet={isTablet} />
                </div>
            </div>
            <div className='home-large-bg'>
                <HomeGallery isMobile={isMobile} isTablet={isTablet} />
                <div className='home-small-bg'>
                    <Headline isMobile={isMobile} isTablet={isTablet} isLaptop={isLaptop} data={homeBanhoTosaHero} />
                    <PetServices isMobile={isMobile} data={petServicesData} />
                    <Brands isMobile={isMobile} isTablet={isTablet} />
                </div>
            </div>
            <img src={linkPreview} alt="" className='link-preview' />
        </div>
    );
}

export default Home;