import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import PetsIcon from '@mui/icons-material/Pets';
import CallIcon from '@mui/icons-material/Call';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import './css/Sidebar.css';

function Sidebar(props) {
    const { sidebar, showSidebar } = props;
    const targetRef = useRef();

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside = (event) => {
            if (sidebar && targetRef.current && !targetRef.current.contains(event.target)) {
                showSidebar(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [sidebar]);

    return (
        <div ref={targetRef} className={sidebar ? 'sidebar-menu active' : 'sidebar-menu'}>
            <ul className='sidebar-menu-items' onClick={showSidebar}>
                <li className='sidebar-toggle'>
                    <Link to='#' className='sidebar-toggle-header'>
                        <CloseIcon />
                    </Link>
                </li>
                <li className="sidebar-text">
                    <Link to="/">
                        {/* <div className='home-icon'></div> */}
                        <HomeIcon />
                        <span>Home</span>
                    </Link>
                </li>
                <li className="sidebar-text">
                    <Link to="/banho-tosa">
                        {/* <div className='banho-tosa-icon'></div> */}
                        <PetsIcon />
                        <span>Banho e Tosa</span>
                    </Link>
                </li>
                <li className="sidebar-text">
                    <Link to="/atendimento">
                        {/* <div className='atendimento-icon'></div> */}
                        <CallIcon />
                        <span>Atendimento</span>
                    </Link>
                </li>
                <ul className="sidebar-icons">
                    <li>
                        <a href='https://www.facebook.com/petshopblue/' target='_blank' rel="noreferrer">
                            <FacebookIcon />
                        </a>
                    </li>
                    <li >
                        <a href='https://www.instagram.com/petshopbluejundiai/?hl=en' target='_blank' rel="noreferrer">
                            <InstagramIcon />
                        </a>
                    </li>
                </ul>
            </ul>
        </div>
    );
}

export default Sidebar;