import React from 'react';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import './css/Headline.css';

const Headline = (props) => {
    const { isMobile, isTablet, isLaptop, data } = props;
    const theme = createTheme({
        palette: {
            primary: {
                main: '#00477A',
            }
        },
        typography: {
            button: {
                textTransform: 'none'
            }
        }
    });

    return (
        <div className='headline-container'>
            {data.map((item, i) => (
                <div key={i} className='headline-bg-container' style={{ backgroundImage: `url(${item.bgURL})`, padding: isMobile ? item.paddingSmall : isTablet ? item.paddingMedium : isLaptop ? item.paddingMedium : item.paddingLarge }}>
                    <div className='headline-content' style={{ flexDirection: item.flex, backgroundColor: item.bgColor, boxShadow: item.boxShadow, padding: isMobile ? item.paddingContentSmall : item.paddingContentLarge, borderBottomLeftRadius: isMobile ? item.borderBottomLeft : null, borderBottomRightRadius: isMobile ? item.borderBottomRight : null }}>
                        <div className='headline-image'>
                            <img src={item.img01} alt="" />
                            <img src={item.img02} alt="" />
                            <img src={item.img03} alt="" />
                            <img src={item.img04} alt="" />
                        </div>
                        <div className='headline-text'>
                            <h1>
                                {item.title}
                            </h1>
                            <p>
                                {isMobile ? item.pSmall : item.p}
                            </p>
                            <ThemeProvider theme={theme}>
                                <Button
                                    LinkComponent={Link}
                                    to={item.buttonURL}
                                    /* scroll={el => scrollWithOffset(el)} */
                                    variant="contained"
                                    disableElevation
                                    style={{ color: "#fff" }}
                                /* onClick={onClick} */
                                >
                                    {item.button}
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Headline;