import React, { useEffect } from 'react';
import brand01 from '../../assets/brand01.png';
import brand02 from '../../assets/brand02.png';
import brand03 from '../../assets/brand03.png';
import brand04 from '../../assets/brand04.png';
import brand05 from '../../assets/brand05.png';
import brand06 from '../../assets/brand06.png';
import AOS from "aos";
import "aos/dist/aos.css";
import './css/Brands.css';

const image = [
    brand01,
    brand02,
    brand03,
    brand04,
    brand05,
    brand06
]

const Brands = (props) => {
    const { isMobile, isTablet } = props;

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <div className="brands-container">
            <div className='brands-content'>
                <h2>Marcas Favoritas</h2>
                <div data-aos={`${isTablet ? null : "fade-up"}`} data-aos-offset={isMobile ? "0" : "-100"}>
                    {image.map((item, i) =>
                        <img key={i} src={item} alt="Logo" />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Brands;