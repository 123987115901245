import React from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PetsIcon from '@mui/icons-material/Pets';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import CreditCard from '@mui/icons-material/CreditCard';
import './css/BanhoTosaBenefits.css';

const BanhoTosaBenefits = () => {

    return (
        <div className='banho-tosa-benefits-container'>
            <div className='banho-tosa-benefits-content'>
                <div className='banho-tosa-benefits-box01'>
                    <div className='banho-tosa-benefits-item'>
                        <div className='banho-tosa-benefits-icon banho-tosa-benefits-icon-01'></div>
                        <div>
                            <h4>Trimming</h4>
                        </div>
                    </div>
                    <div className='banho-tosa-benefits-item'>
                        <div className='banho-tosa-benefits-icon banho-tosa-benefits-icon-02'></div>
                        <div>
                            <h4>Hidratação</h4>
                        </div>
                    </div>
                </div>
                <div className='banho-tosa-benefits-box02'>
                    <div className='banho-tosa-benefits-item'>
                        <div className='banho-tosa-benefits-icon banho-tosa-benefits-icon-03'></div>
                        <div>
                            <h4>Corte de Unha</h4>
                        </div>
                    </div>
                    <div className='banho-tosa-benefits-item'>
                        <div className='banho-tosa-benefits-icon banho-tosa-benefits-icon-04'></div>
                        <div>
                            <h4>Desembolo</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BanhoTosaBenefits;