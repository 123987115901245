import React from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PetsIcon from '@mui/icons-material/Pets';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import CreditCard from '@mui/icons-material/CreditCard';
import './css/HomeBenefits.css';

const HomeBenefits = (props) => {
    const { isMobile } = props;

    return (
        <div id="home-benefits" className='home-hero-benefits-container'>
            <div className='home-hero-benefits-content'>
                <div className='home-hero-benefits-box'>
                    <div className='home-hero-benefits-item'>
                        <div className='home-hero-benefits-icon home-hero-benefits-icon-01'></div>
                        <div>
                            <h4>Rações</h4>
                            {isMobile ? (
                                <p>Nutritivas para seu Pet</p>
                            ) : (
                                <p>Ricas em nutrientes para a qualidade de vida do seu pet</p>
                            )}
                        </div>
                    </div>
                    <div className='home-hero-benefits-item'>
                        <div className='home-hero-benefits-icon home-hero-benefits-icon-02'></div>
                        <div>
                            <h4>Banho e Tosa</h4>
                            {isMobile ? (
                                <p>com Equipe Especializada</p>
                            ) : (
                                <p>Equipe especializada em um ambiente seguro</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className='home-hero-benefits-box'>
                    <div className='home-hero-benefits-item'>
                        <div className='home-hero-benefits-icon home-hero-benefits-icon-03'></div>
                        <div>
                            <h4>Acessórios</h4>
                            {isMobile ? (
                                <p>Coleiras, guias e mais</p>
                            ) : (
                                <p>Guias e coleiras para passear com muito estilo</p>
                            )}
                        </div>
                    </div>
                    <div className='home-hero-benefits-item'>
                        <div className='home-hero-benefits-icon home-hero-benefits-icon-04'></div>
                        <div>
                            <h4>Promoções</h4>
                            {isMobile ? (
                                <p>Venha visitar nossa Loja</p>
                            ) : (
                                <p>Visite nossa loja para conhecer nossos produtos</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeBenefits;