import React from 'react';
import ContactForm from '../../components/contact-form/ContactForm';
import './css/Atendimento.css';

const Atendimento = (props) => {
    const { isMobile } = props;

    return (
        <main className='atendimento-container'>
            <div className='atendimento-content'>
                <div className='atendimento-headline-box'>
                    <div className='atendimento-headline'>
                        <h1>Atendimento</h1>
                        {isMobile ? (
                            <p>Estamos abertos em horário comercial para realizar agendamentos e esclarecer todas as dúvidas necessárias.</p>
                        ) : (
                            <p>Estamos abertos em horário comercial com equipe pronta para realizar agendamentos e esclarecer todas as dúvidas necessárias.
                                <br />
                                Contamos também com o serviço disk entrega. Contate-nos para saber mais através de email, telefone ou pelo formulário de contato.
                            </p>
                        )}
                    </div>
                </div>
                <ContactForm isMobile={isMobile} />
            </div>
        </main>
    );
}

export default Atendimento;