import React, { useState, useEffect, useRef } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { HashLink } from 'react-router-hash-link';
import './css/HomeHero.css';

const HomeHero = (props) => {
    const { isMobile, isTablet, data } = props;
    const theme = createTheme({
        palette: {
            primary: {
                main: '#00477A',
            }
        },
        typography: {
            button: {
                textTransform: 'none'
            }
        }
    });

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        let yOffset;
        if (isMobile) {
            yOffset = 0;
        } else {
            yOffset = -40;
        }
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    const settings = {
        autoplay: true,
        autoplaySpeed: 10000,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false
    };

    return (
        <div className='home-hero-container'>
            <div className='home-hero-slider-container'>
                <Slider {...settings}>
                    {data.map((item, i) => (
                        <div key={i} className='home-hero-slider'>
                            <div className='home-hero-slider-content' style={{ flexDirection: isMobile ? item.flexDirection : item.flex }}>
                                <div className='home-hero-slider-content-bg' style={{ backgroundImage: `url(${item.bgImg})` }}></div>
                                <div className='home-hero-headline'>
                                    <h1>
                                        {item.title}
                                    </h1>
                                    <p>
                                        {item.p}
                                    </p>
                                    <ThemeProvider theme={theme}>
                                        <Button
                                            LinkComponent={HashLink}
                                            to="#home-benefits"
                                            scroll={el => scrollWithOffset(el)}
                                            variant="contained"
                                            disableElevation
                                            style={{ color: "#fff" }}
                                        /* onClick={onClick} */
                                        >
                                            {item.button}
                                        </Button>
                                    </ThemeProvider>
                                </div>
                                <div className='home-hero-image'>
                                    {/* <div className='home-hero-hearts'></div> */}
                                    <img src={item.img} alt="" />
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}

export default HomeHero;