import React, { useState, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import "./css/Navbar.css";

const Navbar = () => {
  const [stickyClass, setStickyClass] = useState("");
  const [y, setY] = useState(window.scrollY);

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        setStickyClass("");
      } else if (y < window.scrollY) {
        setStickyClass("navbar-sticky");
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  const [BGColor, setBGColor] = useState("");
  const [scrollUp, setScrollUp] = useState(window.scrollY);
  const handleScrollUp = useCallback((e) => {
    if (scrollUp > window.scrollY) {
      setBGColor("navbar-scroll-up");
    } else if (scrollUp < window.scrollY) {
      setBGColor("navbar-scroll-down");
    }
    if (window.pageYOffset === 0) {
      setBGColor("navbar-scroll-down")
    }
    setScrollUp(window.scrollY)
  }, [scrollUp])

  useEffect(() => {

    window.addEventListener("scroll", handleScrollUp);

    return () => {
      window.removeEventListener("scroll", handleScrollUp);
    };
  }, [handleScrollUp]);

  return (
    <nav className={`${stickyClass} ${BGColor}`}>
      <div className="navbar-container">
        <NavLink to="/">
          <div className="navbar-logo">
            <p>Pet Shop Blue</p>
            {/* <img src={logo} alt="logo" /> */}
          </div>
        </NavLink>
        <ul className="navbar-links">
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/banho-tosa">Banho e Tosa</NavLink>
          </li>
          <li>
            <NavLink to="/atendimento">Atendimento</NavLink>
          </li>
        </ul>
        <div className="navbar-icons">
          <ul>
            <li>
              <a href="https://www.facebook.com/petshopblue/" target="_blank" rel="noreferrer">
                <FacebookIcon />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/petshopbluejundiai/?hl=en" target="_blank" rel="noreferrer">
                <InstagramIcon />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
