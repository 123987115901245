import homeHeroImg01 from './assets/home-hero-img01.png';
import homeHeroImg02 from './assets/home-hero-img02.png';
import homeHeroImg03 from './assets/home-hero-img03.png';
import homeHeroBG01 from './assets/paw-pattern-blue-bg.jpg';
import homeHeroBG02 from './assets/paw-pattern-dark-blue-bg.jpg';
import homeHeroBG03 from './assets/paw-pattern-purple-bg.jpg';
import homeBanhoTosa01 from './assets/home-banho-tosa01.png';
import homeBanhoTosa02 from './assets/home-banho-tosa02.png';
import headlineWhiteFrame from './assets/headline-white-frame.png';
import headlineBlueFrame from './assets/headline-blue-frame.png';
import blueBall from './assets/blue-ball.png';
import banhoTosaHero from './assets/banho-tosa-hero.png';
import banhoTosaCat01 from './assets/banho-tosa-cat01.png';
import banhoTosaCat02 from './assets/banho-tosa-cat02.png';
import petServicesIcon01 from './assets/dog-food.svg';
import petServicesIcon02 from './assets/dog-food.svg';
import petServicesIcon03 from './assets/dog-food.svg';

export const homeHero = [
    {
        img: homeHeroImg01,
        bgImg: homeHeroBG01,
        flex: 'row',
        flexDirection: 'column-reverse',
        title: "Aqui Você Encontra Tudo Para Seu Pet",
        p: "Produtos e serviços para cuidados com a alimentação e higiene do seu pet com muito carinho e atenção",
        button: "Saiba mais",
        buttonURL: "/banho-tosa",
    },
    {
        img: homeHeroImg02,
        bgImg: homeHeroBG02,
        flex: 'row-reverse',
        flexDirection: 'column-reverse',
        title: "Rações nutritivas com os melhores preços",
        p: "Rações secas e molhadas para garantir o bem-estar e a nutrição dos nossos amigos pet",
        button: "Eu quero",
        buttonURL: "/banho-tosa",
    },
    {
        img: homeHeroImg03,
        bgImg: homeHeroBG03,
        flex: 'row',
        flexDirection: 'column-reverse',
        title: "Acessórios para cães, gatos, aves e roedores",
        p: "Produtos para higiene, descartáveis, coleira, guias, caminhas, roupas e brinquedos e muito mais",
        button: "Clique e Conheça",
        buttonURL: "/banho-tosa",
    }
]

export const homeBanhoTosaHeadline = [
    {
        img01: homeBanhoTosa01,
        img02: homeBanhoTosa02,
        img03: blueBall,
        img04: headlineWhiteFrame,
        title: "Serviço de Banho e Tosa Para Cães e Gatos",
        pSmall: "Possuímos equipe treinada e um ambiente equipado e seguro, além de banheiras adaptadas para todos os portes caninos",
        p: "Possuímos equipe treinada e um ambiente equipado e seguro, além de banheiras adaptadas para todos os portes caninos",
        button: "Conheça mais",
        buttonURL: "/banho-tosa",
        paddingSmall: "0 0 4rem 0",
        paddingMedium: "3rem 0 3rem 0",
        paddingLarge: "3rem 0 3rem 0"
    }
]

export const banhoTosaHeroHeadline = [
    {
        img01: banhoTosaHero,
        title: "Serviço de Banho e Tosa Para Cães",
        pSmall: "Utilizamos linha de shampoo neutra garantindo a maciez dos pelos e brilho com efeito prolongado",
        p: "Utilizamos linha de shampoo neutra garantindo a maciez dos pelos e brilho com efeito prolongado.\nTosa higiênica no focinho, barriga, bumbum finalizando com corte de unha e arredondamento das patas e limpeza dos ouvidos.",
        button: "Agende um Horário",
        buttonURL: "/atendimento",
        paddingSmall: "5rem 0rem 4rem 0rem",
        paddingMedium: "7rem 0 .5rem 0",
        paddingLarge: "8rem 0 2rem 0",
    }
]

export const banhoTosaCatHeadline = [
    {
        img01: banhoTosaCat01,
        img02: banhoTosaCat02,
        img03: blueBall,
        img04: headlineBlueFrame,
        title: "Banho e Tosa Higiênica Para Gatos",
        pSmall: "Realizamos também este serviço especializado para felinos, para remoção de nós e excesso de pelos para reduzir a queda e formação de bolas de pelo no organismo do gato",
        p: "Realizamos também este serviço especializado para felinos, para remoção de nós e excesso de pelos para reduzir a queda e formação de bolas de pelo no organismo do gato",
        button: "Agende um Horário",
        buttonURL: "/atendimento",
        flex: "row-reverse",
        paddingSmall: "0 0 0 0",
        paddingMedium: "2rem 2rem 6rem 2rem",
        paddingLarge: "2rem 0 6rem 0",
        paddingContentSmall: "3rem 0rem 8rem 0rem",
        paddingContentLarge: "2rem 4rem",
        bgColor: "var(--clr-primary-blue)",
        borderBottomRight: "0",
        borderBottomLeft: "0",
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"
    }
]

export const petServicesData = [
    {
        icon: petServicesIcon01,
        aos: "fade-right",
        title: "Banho e Tosa Higiênica",
        p: "Com shampoo neutro para garantir maciez e brilho dos pelos com efeito prolongado"
    },
    {
        icon: petServicesIcon02,
        aos: "fade-left",
        title: "Banho e Tosa na Máquina",
        p: "3 alturas de aparo nos pelos. Lâmina 4 (2cm) | Lâmina 7 (1cm) | Lâmina 10 (rente a pele)"
    },
    {
        icon: petServicesIcon03,
        aos: "fade-right",
        title: "Banho e Tosa na Tesoura",
        p: "Inclui aparo nos pelos arredondados com a tesoura em altura acima de 2cm"
    }
]