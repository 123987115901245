import React, { useState, useEffect, useRef } from 'react';
import dogTie from '../../assets/schnauzer-with-tie.png';
import ShowerOutlinedIcon from '@mui/icons-material/ShowerOutlined';
import ChargingStationOutlinedIcon from '@mui/icons-material/ChargingStationOutlined';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import AOS from "aos";
import "aos/dist/aos.css";
import './css/PetServices.css';

const PetServices = (props) => {
    const { isMobile, petServicesData } = props;
    const [rerender, setRerender] = useState(false);
    const targetRef = useRef();
    const [height, setHeight] = useState();

    useEffect(() => {
        setTimeout(() => {
            setRerender(!rerender);
        }, 1);
        const refHeight = targetRef.current.clientHeight;
        setHeight(refHeight);
    }, [rerender]);

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <div className='home-banho-tosa-service-container' data-aos={`${isMobile ? "fade-up" : null}`}>
            <div className='home-banho-tosa-service-content'>
                { }
                <div className='home-banho-tosa-service-box'>
                    <div className='home-banho-tosa-service-item' data-aos={`${isMobile ? "fade-right" : null}`}>
                        <div className='home-banho-tosa-service-title'>
                            <div className='home-banho-tosa-service-icon home-banho-tosa-service-icon-01'></div>
                            <h4>Banho e Tosa Higiênica</h4>
                        </div>
                        <div className='home-banho-tosa-service-text'>
                            <p>Com shampoo neutro para garantir maciez e brilho dos pelos com efeito prolongado</p>
                        </div>
                    </div>
                    <div className='home-banho-tosa-service-item' data-aos={`${isMobile ? "fade-left" : null}`}>
                        <div className='home-banho-tosa-service-title'>
                            <div className='home-banho-tosa-service-icon home-banho-tosa-service-icon-02'></div>
                            <h4>Banho e Tosa na Máquina</h4>
                        </div>
                        <div className='home-banho-tosa-service-text'>
                            <p>3 alturas de aparo nos pelos. Lâmina 4 (2cm) | Lâmina 7 (1cm) | Lâmina 10 (rente a pele)</p>
                        </div>
                    </div>
                    <div className='home-banho-tosa-service-item' data-aos={`${isMobile ? "fade-right" : null}`}>
                        <div className='home-banho-tosa-service-title'>
                            <div className='home-banho-tosa-service-icon home-banho-tosa-service-icon-03'></div>
                            <h4>Banho e Tosa na Tesoura</h4>
                        </div>
                        <div className='home-banho-tosa-service-text'>
                            <p>Inclui aparo nos pelos arredondados com a tesoura em altura acima de 2cm</p>
                        </div>
                    </div>
                </div>
                <div ref={targetRef} className='home-banho-tosa-service-image' data-aos="fade-up" data-aos-offset={isMobile ? "0" : "-200"}>
                    {/* <div className='home-banho-tosa-service-bg' style={{ height: height }}></div> */}
                    <img src={dogTie} alt="Dog" />
                </div>
            </div>
        </div>
    );
}

export default PetServices;

/* 
{
    data?.map((item, i) => (
        <div key={i} className='home-banho-tosa-service-box'>
            <div className='home-banho-tosa-service-item' data-aos={isMobile ? item.aos : null}>
                <div className='home-banho-tosa-service-title'>
                    <div className='home-banho-tosa-service-icon home-banho-tosa-service-icon-01' style={{ backgroundImage: `url(${item.icon})` }}></div>
                    <h4>{item.title}</h4>
                </div>
                <div className='home-banho-tosa-service-text'>
                    <p>{item.p}</p>
                </div>
            </div>
        </div>
    ))
}
 */