import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Dog from "../../assets/gallery-dog.png";
import Cat from "../../assets/gallery-cat.png";
import Bird from "../../assets/gallery-bird.png";
import Fish from "../../assets/gallery-fish.png";
import Hamster from "../../assets/gallery-hamster.png";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import './css/HomeGallery.css';

const HomeGallery = (props) => {
    const { isMobile, isTablet, isDesktop } = props;

    const settingsMobile = {
        autoplay: true,
        autoplaySpeed: 2500,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        nextArrow: <ArrowForwardIosOutlinedIcon />,
        prevArrow: <ArrowBackIosOutlinedIcon />
    };
    const settingsTablet = {
        autoplay: true,
        autoplaySpeed: 2500,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        pauseOnHover: false,
        nextArrow: <ArrowForwardIosOutlinedIcon />,
        prevArrow: <ArrowBackIosOutlinedIcon />
    };
    const settingsDesktop = {
        autoplay: true,
        autoplaySpeed: 2500,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        pauseOnHover: false,
        nextArrow: <ArrowForwardIosOutlinedIcon />,
        prevArrow: <ArrowBackIosOutlinedIcon />
    };

    return (
        <div className='gallery-container'>
            <div className='home-gallery-transparent-bg'></div>
            <div className='gallery-content'>
                <h2 id='home-gallery'>Produtos e Serviços para</h2>
                {isMobile ? (
                    <Slider {...settingsMobile}>
                        <div className='gallery-item'>
                            <img src={Dog} alt="dog" />
                            <div className='gallery-bg'></div>
                            <h3>Cães</h3>
                        </div>
                        <div className='gallery-item'>
                            <img src={Cat} alt="dog" />
                            <div className='gallery-bg'></div>
                            <h3>Gatos</h3>
                        </div>
                        <div className='gallery-item'>
                            <img src={Bird} alt="dog" />
                            <div className='gallery-bg'></div>
                            <h3>Pássaros</h3>
                        </div>
                        <div className='gallery-item'>
                            <img src={Fish} alt="dog" />
                            <div className='gallery-bg'></div>
                            <h3>Peixes</h3>
                        </div>
                        <div className='gallery-item'>
                            <img src={Hamster} alt="dog" />
                            <div className='gallery-bg'></div>
                            <h3>Hamsters</h3>
                        </div>
                    </Slider>
                ) : isTablet ? (
                    <Slider {...settingsTablet}>
                        <div className='gallery-item'>
                            <img src={Dog} alt="dog" />
                            <div className='gallery-bg'></div>
                            <h3>Cães</h3>
                        </div>
                        <div className='gallery-item'>
                            <img src={Cat} alt="dog" />
                            <div className='gallery-bg'></div>
                            <h3>Gatos</h3>
                        </div>
                        <div className='gallery-item'>
                            <img src={Bird} alt="dog" />
                            <div className='gallery-bg'></div>
                            <h3>Pássaros</h3>
                        </div>
                        <div className='gallery-item'>
                            <img src={Fish} alt="dog" />
                            <div className='gallery-bg'></div>
                            <h3>Peixes</h3>
                        </div>
                        <div className='gallery-item'>
                            <img src={Hamster} alt="dog" />
                            <div className='gallery-bg'></div>
                            <h3>Hamsters</h3>
                        </div>
                    </Slider>
                ) : (
                    <Slider {...settingsDesktop}>
                        <div className='gallery-item'>
                            <img src={Dog} alt="dog" />
                            <div className='gallery-bg'></div>
                            <h3>Cães</h3>
                        </div>
                        <div className='gallery-item'>
                            <img src={Cat} alt="dog" />
                            <div className='gallery-bg'></div>
                            <h3>Gatos</h3>
                        </div>
                        <div className='gallery-item'>
                            <img src={Bird} alt="dog" />
                            <div className='gallery-bg'></div>
                            <h3>Pássaros</h3>
                        </div>
                        <div className='gallery-item'>
                            <img src={Fish} alt="dog" />
                            <div className='gallery-bg'></div>
                            <h3>Peixes</h3>
                        </div>
                        <div className='gallery-item'>
                            <img src={Hamster} alt="dog" />
                            <div className='gallery-bg'></div>
                            <h3>Hamsters</h3>
                        </div>
                    </Slider>
                )}
            </div>
        </div>
    );
}

export default HomeGallery;