import React, { useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dogSmall from "../../assets/banho-tosa-dog-small.png";
import dogMedium from "../../assets/banho-tosa-dog-medium.png";
import dogLarge from "../../assets/banho-tosa-dog-large.png";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import AOS from "aos";
import "aos/dist/aos.css";
import './css/BanhoTosaGallery.css';

const BanhoTosaGallery = (props) => {
    const { isMobile, isTablet, isDesktop } = props;

    const settingsMobile = {
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <ArrowForwardIosOutlinedIcon />,
        prevArrow: <ArrowBackIosOutlinedIcon />
    };
    const settingsTablet = {
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <ArrowForwardIosOutlinedIcon />,
        prevArrow: <ArrowBackIosOutlinedIcon />
    };
    const settingsDesktop = {
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <ArrowForwardIosOutlinedIcon />,
        prevArrow: <ArrowBackIosOutlinedIcon />
    };

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <div className='banho-tosa-gallery-container'>
            <div className='banho-tosa-gallery-content' data-aos={isTablet ? null : 'fade-up'}>
                {isMobile ? (
                    <h2>Banho e Tosa para <br /> Cães de</h2>
                ) : (
                    <h2>Banho e Tosa para Cães de</h2>
                )}
                {isMobile ? (
                    <Slider {...settingsMobile}>
                        <div className='banho-tosa-gallery-item'>
                            <img src={dogSmall} alt="dog" />
                            <div className='banho-tosa-gallery-bg'></div>
                            <h3>Porte Pequeno</h3>
                        </div>
                        <div className='banho-tosa-gallery-item'>
                            <img src={dogMedium} alt="dog" />
                            <div className='banho-tosa-gallery-bg'></div>
                            <h3>Porte Médio</h3>
                        </div>
                        <div className='banho-tosa-gallery-item'>
                            <img src={dogLarge} alt="dog" />
                            <div className='banho-tosa-gallery-bg'></div>
                            <h3>Porte Grande</h3>
                        </div>
                    </Slider>
                ) : isTablet ? (
                    <Slider {...settingsTablet}>
                        <div className='banho-tosa-gallery-item'>
                            <img src={dogSmall} alt="dog" />
                            <div className='banho-tosa-gallery-bg'></div>
                            <h3>Porte Pequeno</h3>
                        </div>
                        <div className='banho-tosa-gallery-item'>
                            <img src={dogMedium} alt="dog" />
                            <div className='banho-tosa-gallery-bg'></div>
                            <h3>Porte Médio</h3>
                        </div>
                        <div className='banho-tosa-gallery-item'>
                            <img src={dogLarge} alt="dog" />
                            <div className='banho-tosa-gallery-bg'></div>
                            <h3>Porte Grande</h3>
                        </div>
                    </Slider>
                ) : (
                    <Slider {...settingsDesktop}>
                        <div className='banho-tosa-gallery-item'>
                            <img src={dogSmall} alt="dog" />
                            <div className='banho-tosa-gallery-bg'></div>
                            <h3>Porte Pequeno</h3>
                        </div>
                        <div className='banho-tosa-gallery-item'>
                            <img src={dogMedium} alt="dog" />
                            <div className='banho-tosa-gallery-bg'></div>
                            <h3>Porte Médio</h3>
                        </div>
                        <div className='banho-tosa-gallery-item'>
                            <img src={dogLarge} alt="dog" />
                            <div className='banho-tosa-gallery-bg'></div>
                            <h3>Porte Grande</h3>
                        </div>
                    </Slider>
                )}
            </div>
        </div>
    );
}

export default BanhoTosaGallery;